export const CARD_LOGO_SRC_MAP = {
  // AU
  CCAUGOM: { logo: "go.png" },
  CCAUGOB: { logo: "go.png" },
  CCAUGEM: { logo: "gem.png" },
  CCAUBE: { logo: "BuyersEdge.png" },
  CCAUGAFS: { logo: "Creditline.png" },
  CCAUCLN: { logo: "Creditline.png" },
  // NZ
  CCNZOCV: { logo: "GemNZ.png" },
  CCNZGEM: { logo: "GemNZ.png" },
  CCNZCLN: { logo: "CreditlineNZ.png" },
};

export const CARD_LOGO_ICON_MAP = {
  CCAUDJI: { icon: "davidJonesIcon" },
  CCAUDJG: { icon: "davidJonesPrestige" },
  CCAUDJM: { icon: "davidJonesPremiere" },
  EMPTY: { icon: "emptyCreditCard" },
};
export const TOKEN_APPLY_SECTION = {
  APPLY_HEADER: "New To Latitude?",
  APPLY_DESCRIPTION: "Available on participating Latitude credit cards only. If you don't already have a Latitude credit card, you can apply online in as little as 10 minutes.",
  APPLY_BUTTON_TEXT: "Apply Online",
  APPLY_CARD_DESCRIPTION: `Complete your 10-15 minute online application at the Latitude Gem Visa site. Provide your income and employment details as well as your household expenses. You’ll receive a response in under 60 seconds!`,
  APPLY_APPROVED_HEADER: "Once approved you can resume your purchase by logging in, otherwise return to cart to review other options.",
  APPLY_BUTTON : 'Apply'

}